<nav
  class="bg-gray-100 dark:bg-gray-900 fixed w-full z-20 top-0 start-0 border-b border-gray-200 dark:border-gray-600 shadow-[0px_3px_6px_#00000029]">
  <div class="w-full flex flex-wrap items-center justify-between px-[16px] md:px-0 lg:px-10">
    <a (click)="goHome()" routerLinkActive="router-link-active"
      class="flex items-center space-x-3 rtl:space-x-reverse p-3">
      <img src="assets/images/quovofinal2024.svg" class="h-10 lg:h-16" alt="Quovo">
    </a>
    <div class="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
      <button type="button"
        class="bg-transparent text-quovo-gray-dark hover:cursor-pointer no-underline text-base lg:text-xl+ font-thin menu-font-light mr-2"
        (click)="selectLanguage($event,'it')">
        <img alt="IT" class="icon" src="../../../assets/images/italy.png" class="h-5 w-5 lg:w-8 lg:h-8"
          [class.activeLanguage]="isSelectedLanguage('it')" />
      </button>
      <button type="button"
        class="bg-transparent text-quovo-gray-dark hover:cursor-pointer no-underline text-base lg:text-xl+ font-thin menu-font-light mr-3"
        (click)="selectLanguage($event,'en')">
        <img alt="EN" class="icon" src="../../../assets/images/united-kingdom.png" class="h-5 w-5 lg:w-8 lg:h-8"
          [class.activeLanguage]="isSelectedLanguage('en')" />
      </button>
      @if (isUserLogged){
          <div class="dropdown inline-flex">
              <button type="button" style="margin-left: 30px; margin-right: 30px" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="toggleUserMenu()"
                      class="bg-transparent text-quovo-gray-dark hover:cursor-pointer no-underline text-base xl:text-2xl font-thin menu-font-light p-1">
                        <i class="fa-solid fa-user"></i>
              </button>
                                <div class="dropdown-menu mt-3" aria-labelledby="dropdownMenuButton">
                                    <ul class="pl-4">
                                        <li class="my-2">
                                            <button type="button"
                                                    class="bg-transparent text-quovo-gray-dark hover:cursor-pointer no-underline text-base xl:text-xl+ font-thin menu-font-light"
                                                    (click)="onReservations()">{{ 'navbar.reservations' | translate }}</button>
                                        </li>
                                        <li class="my-2">
                                            <button type="button"
                                                    class="bg-transparent text-quovo-gray-dark hover:cursor-pointer no-underline text-base xl:text-xl+ font-thin menu-font-light"
                                                    (click)="onFavorites()"
                                            >{{ 'navbar.favorites' | translate }}</button>
                                        </li>
                                        <li class="my-2">
                                            <button type="button"
                                                    class="bg-transparent text-quovo-gray-dark hover:cursor-pointer no-underline text-base xl:text-xl+ font-thin menu-font-light"
                                                    (click)="onLogout()"
                                            >{{ 'login.logout' | translate }}</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
      } @else {
      <button type="button"
        class="bg-transparent text-quovo-gray-dark hover:cursor-pointer no-underline text-base xl:text-xl+ font-thin menu-font-light pl-3"
        (click)=" onLogin()">{{ 'login.login' | translate }}</button>
      }
      <button type="button"
        class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        (click)="toggleNavbar()">
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M1 1h15M1 7h15M1 13h15" />
        </svg>
      </button>
    </div>
    <!-- <div class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
      [ngClass]="{'hidden': !showUserMenu, 'flex': showUserMenu}">
      <ul
        class="flex flex-col mt-4 font-medium bg-gray-100 md:space-x-4 lg:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
        <li>
          <button type="button"
            class="bg-transparent text-quovo-gray-dark hover:cursor-pointer no-underline text-base xl:text-xl+ font-thin menu-font-light pl-3"
            (click)=" onLogin()">{{ 'login.logout' | translate }}</button>
        </li>
        <li>
          <a class="block bg-transparent text-quovo-red hover:cursor-pointer no-underline text-base xl:text-xl+ font-thin menu-font-light"
            (click)="select(SearchCategoryEnum.RESTAURANTS)">{{ 'navbar.nav-item2' | translate }}</a>
        </li>
        <li>
          <a class="block bg-transparent text-quovo-red hover:cursor-pointer no-underline text-base xl:text-xl+ font-thin menu-font-light"
            (click)="select(SearchCategoryEnum.DISHES)">{{ 'navbar.nav-item3' | translate }}</a>
        </li>
        <li>
          <a class="block bg-transparent text-quovo-red hover:cursor-pointer no-underline text-base xl:text-xl+ font-thin menu-font-light"
            (click)="select(SearchCategoryEnum.CHEFS)">{{ 'navbar.nav-item4' | translate }}</a>
        </li>
        <li>
          <a class="block bg-transparent text-quovo-red hover:cursor-pointer no-underline text-base xl:text-xl+ font-thin menu-font-light"
            (click)="select(SearchCategoryEnum.EXPERIENCES)">{{ 'navbar.nav-item5' | translate }}</a>
        </li>
        <li>
          <a class="block bg-transparent text-quovo-gray-dark hover:cursor-pointer no-underline text-base xl:text-xl+ font-thin menu-font-light"
            (click)="select(SearchCategoryEnum.EVENT)">Blog</a>
        </li>
      </ul>
    </div> -->
    <div class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
      [ngClass]="{'hidden': !showMenu, 'flex': showMenu}">
      <ul
        class="flex flex-col mt-4 font-medium bg-gray-100 md:space-x-4 lg:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
        <li>
          <a class="block bg-transparent text-quovo-yellow hover:cursor-pointer no-underline text-base xl:text-xl+ font-thin menu-font-light"
            (click)="select(SearchCategoryEnum.QINOA)">{{ 'navbar.nav-item1' | translate }}</a>
        </li>
        <li>
          <a class="block bg-transparent text-quovo-red hover:cursor-pointer no-underline text-base xl:text-xl+ font-thin menu-font-light"
            (click)="select(SearchCategoryEnum.RESTAURANTS)">{{ 'navbar.nav-item2' | translate }}</a>
        </li>
        <li>
          <a class="block bg-transparent text-quovo-red hover:cursor-pointer no-underline text-base xl:text-xl+ font-thin menu-font-light"
            (click)="select(SearchCategoryEnum.DISHES)">{{ 'navbar.nav-item3' | translate }}</a>
        </li>
        <li>
          <a class="block bg-transparent text-quovo-red hover:cursor-pointer no-underline text-base xl:text-xl+ font-thin menu-font-light"
            (click)="select(SearchCategoryEnum.CHEFS)">{{ 'navbar.nav-item4' | translate }}</a>
        </li>
        <li>
          <a class="block bg-transparent text-quovo-red hover:cursor-pointer no-underline text-base xl:text-xl+ font-thin menu-font-light"
            (click)="select(SearchCategoryEnum.EXPERIENCES)">{{ 'navbar.nav-item5' | translate }}</a>
        </li>
        <li>
          <a class="block bg-transparent text-quovo-gray-dark hover:cursor-pointer no-underline text-base xl:text-xl+ font-thin menu-font-light"
            (click)="select(SearchCategoryEnum.EVENT)">Blog</a>
        </li>
      </ul>
    </div>
  </div>
</nav>