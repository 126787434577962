import { CommonModule } from '@angular/common';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { SearchCategoryEnum } from "../../models/enums/search-category.enum";
import { Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "../../services/authentication.service";
import { LanguageService } from "../../services/language.service";
import { Dropdown } from "flowbite";
import {StorageKey} from "../../../environments/environment.storage";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  styleUrl: './navbar.component.scss'
})

export class NavbarComponent implements OnInit {

  protected readonly SearchCategoryEnum = SearchCategoryEnum;
  isUserLogged: boolean = false;
  showMenu = false;

  constructor(
    private route: Router,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private languageService: LanguageService
  ) {

  }

  ngOnInit() {
    this.authenticationService.isUserLogged$.subscribe(logged => this.isUserLogged = logged);
    if (localStorage.getItem(StorageKey.isLogged)) {
        this.isUserLogged = true;
    }else {
        this.isUserLogged = false;
    }
  }

  toggleNavbar() {
    this.showMenu = !this.showMenu;
  }

  toggleUserMenu() {
    if(this.showMenu && window.innerWidth < 768) {
        this.showMenu = false;
    }
  }

  onReservations() {
    this.route.navigate(['/reservations']);
  }

  onFavorites() {
    this.route.navigate(['/favorites']);
  }

  select(item: SearchCategoryEnum) {
    this.toggleNavbar();
    switch (item) {
      case SearchCategoryEnum.RESTAURANTS:
        console.log('Navigating to restaurants');
        this.route.navigate(['restaurants/all'])
        break;
      case SearchCategoryEnum.DISHES:
        this.route.navigate(['dishes/all'])
        break;
      case SearchCategoryEnum.EXPERIENCES:
        this.route.navigate(['experiences/all'])
        break;
      case SearchCategoryEnum.QINOA:
        this.route.navigate(['home'])
        break;
      case SearchCategoryEnum.CHEFS:
        this.route.navigate(['protagonists/all'])
        break;
      case SearchCategoryEnum.EVENT:
        // write route path for all events here
        break;
      default:
        break;
    }
  }

  click() {
    this.route.navigate(['restaurants']);
  }

  selectLanguage(event: Event, selectedLanguage: string): void {
    event.preventDefault();
    localStorage.setItem(StorageKey.lang, selectedLanguage);
    this.authenticationService.setLang(selectedLanguage);
    this.changeLanguage(selectedLanguage);
    localStorage.getItem(StorageKey.lang);
    this.route.navigate([this.route.url]);
  }

  changeLanguage(selectedLanguage: any): void {
    const Browser_Lang = this.translateService.getBrowserLang();
    const defaultLang = this.translateService.getDefaultLang();
    this.translateService.addLangs(['it', 'en']);
    let lang = localStorage.getItem(StorageKey.lang);
    if (lang) {
      this.translateService.use(lang);
    }
    localStorage.setItem(StorageKey.lang, selectedLanguage);
    this.translateService.use(selectedLanguage);
    this.languageService.setData(selectedLanguage);
  }

  onLogin() {
    this.route.navigate(['/login']);
  }

  isSelectedLanguage(lang: string) {
    return localStorage.getItem(StorageKey.lang) === lang;
  }

  goHome() {
    this.route.navigate(['/']);
  }

  onLogout() {
    this.authenticationService.restoreUserData();
    this.isUserLogged = false;
    localStorage.removeItem(StorageKey.isLogged)
    this.authenticationService.removeAuthentication().subscribe({
      next: (res) => {
        console.log(res)
        this.authenticationService.restoreUserData();
      },
      error: (err) => {
        console.log(err)
      }
    })
  }

}
